<template>
	<div class="p-3 py-4" style="max-height: 100vh; overflow-y: auto;">
		<div class="close-btn" v-on:click="$emit('close', {})">
			<font-awesome-icon class="m-auto" icon="times" />
		</div>
		<div class="share-modal-body">
			<h4>{{ $t('share.title') }}</h4>
			<div class="search-input">
				<font-awesome-icon icon="search" />
				<input type="text" v-model="search" :placeholder="$t('share.search')" />
			</div>
<!--			<h6 class="mt-2 mb-0">-->
<!--				<b>{{filtered.length}}</b> {{$t('planes.planesFound')}}-->
<!--			</h6>-->
			<div class="users" >
				<div class="nousers" v-if="selected.length === 0 && selectedEmails.length === 0 && filtered.length === 0 && !loading && search.length === 0">
					{{$t('share.noUsers')}}
				</div>
				<!--		Entered email does not exists in the db		-->
				<div class="user" v-if="customEmail  && search.length > 0" @click.stop="selectEmail(customEmail)">
					<div class="avatar-wrapper">
						<Avatar :empty="true" />
					</div>
					<div class="user-data-wrapper">
						<div class="email">{{ customEmail }}</div>
					</div>
					<div class="actions">
						<Button class="btn btn-primary" @click.stop="selectEmail(customEmail)">
							<font-awesome-icon icon="plus" class="m-0" />
						</Button>
					</div>
				</div>

				<!--		Selected emails that does not exists in the db		-->
				<div class="user" v-for="email in selectedEmails"  v-bind:key="email">
					<div class="avatar-wrapper">
						<Avatar :empty="true" />
					</div>
					<div class="user-data-wrapper">
						<div class="email">{{ email }}</div>
					</div>
					<div class="actions">
						<Button class="btn btn-danger" @click="deleteEmail(email)">
							<font-awesome-icon icon="minus" class="m-0" />
						</Button>
					</div>
				</div>

				<!--		Selected users		-->
				<div class="user" v-for="user in selected"  v-bind:key="user.id">
					<div class="avatar-wrapper">
						<Avatar :user="user" />
					</div>
					<div class="user-data-wrapper">
						<div class="name" v-if="user.name">{{ user.name }}</div>
						<div class="email"> @{{ user.username }}</div>
					</div>
					<div class="actions">
						<Button class="btn btn-danger" @click="deleteUser(user)">
							<font-awesome-icon icon="minus" class="m-0" />
						</Button>
					</div>
				</div>

				<!--		All the unselected users that was found by the search		-->
				<template v-if="!loading">
					<div class="user" v-for="user in filtered"  v-bind:key="user.id" @click.stop="selectUser(user)">
						<div class="avatar-wrapper">
							<Avatar :avatar="user.avatar" :avatarColor="user.avatarColor" />
						</div>
						<div class="user-data-wrapper">
							<div class="name">{{ user.name }}</div>
							<div class="email"> @{{ user.username }}</div>
						</div>
						<div class="actions">
							<Button class="btn btn-primary" @click.stop="selectUser(user)">
								<font-awesome-icon icon="plus" class="m-0" />
							</Button>
						</div>
					</div>
				</template>

				<Skeleton  v-if="loading" :count="1" width="100%"  class="skeletons" height="70px" />
			</div>
			<div class="modal-actions">
				<button class="btn btn-primary" @click="share" :disabled="this.selectedEmails.length === 0 && this.selected.length === 0">
					<font-awesome-icon class="m-0" :icon="['fas', 'share']" />
					{{$t('share.share')}}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Avatar from '../profile/avatar/Avatar.vue';
import { ALLOWED_TYPES } from './ShareButton.vue';
import { Skeleton } from 'vue-loading-skeleton';


export default {
	name: "ShareModal",
	components: {
		Avatar,
		Skeleton,
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		sharedId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			search: '',
			/** @type {User[]} */
			selected: [],
			/** @type {string[]} */
			selectedEmails: [],
			/** @type {User[]} */
			filtered: [],
			searchTimeout: null,
			noUsersFound: false,
			/** @type {User[]} */
			usersFound: [],
			loading: false,
			/** @type {(string|null)} */
			customEmail: null,
		}
	},
	watch: {
		search(v) {
			this.loading = false;
			if(v === '') {
				this.updateFiltered([]);
				return;
			}
			this.noUsersFound = false;

			if(this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}

			if(this.validateEmail(this.search)) {
				this.customEmail = this.search;
			}else{
				this.customEmail = null;
			}


			this.loading = true;
			this.searchTimeout = setTimeout(async () => {
				this.searchTimeout = null;
				this.usersFound = await this.$rest.shareSearch(this.type, this.sharedId, this.search);

				if(this.usersFound.length === 0) {
					this.noUsersFound = true;
					this.loading = false;
					this.updateFiltered([]);
					return;
				}

				this.updateFiltered(this.usersFound);
				this.loading = false;
			}, 500);
		}
	},
	destroyed() {
		if (this.searchTimeout) {
			clearTimeout(this.searchTimeout);
		}
	},
	mounted() {
		if (!ALLOWED_TYPES.includes(this.type)) {
			throw new Error("Invalid type for ShareModal");
		}
	},
	methods: {
		updateFiltered(data) {
			const userData = this.$store.getters.getLoggedInUserData;
			this.filtered = data.filter((filteredUser) => {
				if((userData || {}).id === filteredUser.id) {
					return false;
				}
				return !this.selected.some((selectedUser) => {
					return selectedUser.id === filteredUser.id;
				})
			})

			if(this.filtered.length !== 0) {
				this.customEmail = null;
			}
		},

		/** @param {string} email*/
		selectEmail(email) {
			this.customEmail = null;
			this.selectedEmails.push(email);
			this.updateFiltered(this.filtered);
		},

		/** @param {string} email*/
		deleteEmail(email) {
			this.selectedEmails = this.selectedEmails.filter((selectedEmail) => {
				return selectedEmail !== email;
			})
		},

		/** @param {User} user*/
		selectUser(user) {
			this.selected.push(user);
			this.updateFiltered(this.filtered);
		},
		/** @param {User} user*/
		deleteUser(user) {
			this.selected = this.selected.filter((selectedUser) => {
				return selectedUser.id !== user.id;
			})
			this.updateFiltered(this.usersFound);
		},


		async share() {
			if(this.selectedEmails.length === 0 && this.selected.length === 0) {
				return;
			}
			await this.$rest.share(this.type, this.sharedId, this.selectedEmails, this.selected.map(u => u.id));
			this.$emit('close');
		},
		validateEmail(email) {
			if(email === this.$store.getters.getLoggedInUserData.email) {
				return false;
			}
			return String(email)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
		},
	}
}
</script>

<style scoped>
.close-btn {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.modal-actions {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
}

.share-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}


.search-input {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 8px;
	margin-top: 0.6rem;
	padding: 0.5rem 1rem;
	width: 100%;
	border: 1px solid #97a3a4;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
}

.search-input input {
	border: none;
	background-color: transparent;
	width: 100%;
	padding: 0.5rem 1rem;
}

.users {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100px;
	max-height: 400px;
	overflow-y: auto;
	//border-radius: 0.5rem;
	//border: solid 1px #ccc;
	margin-top: 1rem;
	gap: 0.5rem;
	padding: 0.5rem;
}

.nousers {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.filtered-users, .selected-users {
	display: flex;
	flex-direction: column;
	/* max-height: 300px; */
	align-items: flex-start;
}




.user {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
	border: 1px solid #97a3a4;
	width: 100%;
	height: 70px;
	transition: scale 0.1s ease-in-out;
	cursor:pointer;
}

.user:hover {
	background-color: #fdfdfd;
	scale: 1.004;
}

.avatar-wrapper {
	width: 50px;
	height: 50px;
	flex-shrink: 0;
	margin-right: 10px;
}

.avatar-wrapper > div {
	height: 100%;
}

.user-data-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.user .name {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight: bold;
}
.user .email {
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	font-size: 0.9em;
}

.user-data-wrapper {
	overflow: hidden;
}

@media screen and (max-width: 500px)  {
	.user .name {
		font-size: 0.8rem;
	}

	.user .email {
		font-size: 0.8rem;
	}

	.avatar-wrapper {
		width: 40px;
		height: 40px;
	}

	.user .actions {
		flex-shrink: 0;
	}

	.user .actions .btn {
		padding: 0.25rem 0.5rem;
		font-size: .875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}

	.user-data-wrapper {
		flex-shrink: 1;
	}
}


</style>
<style>

.users .skeletons {
	flex: 1;
	width: 100%;
	gap: 0.5rem;
	display: flex;
	flex-direction: column;
}
.users .skeletons .pu-skeleton {
	border-radius: 8px;
	display: block;
}
</style>
