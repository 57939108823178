<template>
	<button @click.stop="onClick()" :class="clazz">
		<font-awesome-icon icon="share-alt" :size="iconSize" />
	</button>
</template>

<script>
import ShareModal from './ShareModal.vue';

export const ALLOWED_TYPES = ["PLANE", "ROUTE", "ROUTE_PLAN"];

export default {
	name: 'ShareButton',
	props: {
		type: {
			type: String,
			required: true,
		},
		sharedId: {
			type: String,
			required: true,
		},
		size: {
			type: String,
			default: "lg",
		}
	},
	computed: {
		iconSize() {
			if(this.size === 'lg') return 'lg';
			if(this.size === 'md') return '1x';
			if(this.size === 'sm') return 'xs';
			return 'lg';
		},
		clazz() {
			return `share-btn ${this.$attrs.class || ''} ${this.size}`;
		},
	},
	mounted() {
		if (!ALLOWED_TYPES.includes(this.type.toUpperCase())) {
			throw new Error("Invalid type for ShareModal");
		}
	},
	methods: {
		onClick() {
			this.$modal.show(ShareModal, {
				type: this.type.toUpperCase(),
				sharedId: this.sharedId,
			}, {
				height: 'auto',
				clickToClose: true,
				adaptive: true,
			}, {
			});
		},
	},
}
</script >

<style scoped>
.share-btn {
	color: white;
	background: #2663a1;
	border-radius: 0.25rem;
	border: 1px solid transparent;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	z-index: 1;
}

.share-btn:not(.no-padding) {
	padding: 0.5rem 0.75rem;
}

.share-btn.lg {
	font-size: 1.5rem;

}

.share-btn.lg:not(.no-padding) {
	padding: 0.5rem 0.75rem;
}


.share-btn.md {
	font-size: 1.1rem;
}

.share-btn.md:not(.no-padding) {
	padding: 0.3rem 0.8rem;
}

.share-btn.sm {
	font-size: .875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.share-btn.sm:not(.no-padding) {
	padding: 0.25rem 0.5rem;
}

.share-btn:hover {
	background: #1e4a7e;
}


</style>
