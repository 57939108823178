<template>
	<div  :style="avatarStyle">
		<img :src="avatarUrl" class="img-fluid h-100" />
	</div>
</template>

<script>

export default {
	name: 'Avatar',
	props: {
		avatar: String,
		avatarColor: String,
		user: Object,
		empty: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			avatarName: null,
			color: null,
		}
	},
	mounted() {
		if(this.user) {
			this.avatarName = this.user.avatar || 'm1';
			this.color = this.user.avatarColor || "#B1CAD8";
		}else{
			this.avatarName = this.avatar || 'm1';
			this.color = this.avatarColor || "#B1CAD8";
		}
	},
	computed: {
		avatarUrl() {
			if(this.empty) return null;
			return `avatars/${this.avatarName}.png`;
		},
		avatarStyle() {
			return {
				borderRadius: "50%",
				backgroundColor: this.color
			}
		},
	}
}
</script>
